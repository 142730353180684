import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import { isEdge } from 'react-device-detect';
import * as yup from 'yup';

import { Button } from '@components/Button';
import PasswordInput from '@components/Formik/PasswordInput';
import { isValidItalyFiscalCodeSchema } from '@components/Formik/validation/fieldDefinitions';
import {
  isRequiredConfirmFC,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';
import Icon from '@components/Icon';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useAddFiscalCode } from '@repositories/government-id';
import { useGetFiscalCode } from '@repositories/government-id/hooks';
import { useFetchProfileDetails } from '@repositories/profile/hooks';

import { constructFullName } from '@utils/constructFullName';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

export interface FormValues {
  fiscalCode: string;
  confirmFiscalCode: string;
}

const ItalyFiscalCodeForm: React.FC = () => {
  const { user } = useAppConfig();

  const validationSchema = (t: TFunction) =>
    yup.object().shape({
      fiscalCode: isValidItalyFiscalCodeSchema(t),
      confirmFiscalCode: schemaField(
        t,
        yup.string(),
        isRequiredConfirmFC
      ).oneOf(
        [yup.ref('fiscalCode'), null],
        t(
          'online-giving.my-donations.italy-fc.confirm-fc-did-not-match]',
          'Please enter a matching Fiscal Code'
        )
      ),
    });

  const individualId = user?.individualId;
  const { t } = useTranslation();

  const [AddFiscalCode] = useAddFiscalCode();
  const { addError, addSuccess } = useNotifications();
  const [state, setState] = useState({
    isDisplay: false,
  });
  const [stateFiscalCode, setStateFiscalCode] = useState({
    userFiscalCodeInfo: '',
  });

  const { data, loading, error } = useFetchProfileDetails(individualId || '');
  useErrorHandling(
    t('view-profile.query.error', 'An error occurred.'),
    error !== undefined,
    'profile-view.error'
  );

  const netForumMemberId = data?.individual.riIndividualId || '';

  const { data: fiscalCodeDATA } = useGetFiscalCode(
    netForumMemberId.toString()
  );

  useEffect(() => {
    if (fiscalCodeDATA?.result?.FiscalCode_c) {
      setStateFiscalCode({
        userFiscalCodeInfo: fiscalCodeDATA?.result.FiscalCode_c.toUpperCase(),
      });
      setState({ ...state, isDisplay: true });
    }
  }, [fiscalCodeDATA]);

  if (error || !individualId) {
    return <p>error</p>;
  }
  if (!data || loading) {
    return <Loading />;
  }

  const {
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
    riIndividualId,
  } = data.individual;

  const fullName = constructFullName({
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  });

  const handleFormSubmit = async (values: FormValues) => {
    const response = await AddFiscalCode({
      variables: {
        fiscalCode: values.fiscalCode.toUpperCase(),
        netForumMemberId: riIndividualId?.toString() || '',
        collectionDate: new Date().toISOString().split('T')[0],
      },
    });
    if (response?.data?.addFiscalCode?.status === 'Success') {
      addSuccess(
        t(
          'online-giving.my-donations.form.success-message',
          'Success! Your entry was saved'
        )
      );
      setState({ isDisplay: true });
      setStateFiscalCode({
        userFiscalCodeInfo: values.fiscalCode.toUpperCase(),
      });
    } else {
      addError(
        t(
          'online-giving.my-donations.form.error-message',
          'Error. Entry was not saved, please try again.'
        )
      );
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{
        fiscalCode: '',
        confirmFiscalCode: '',
      }}
      validationSchema={validationSchema(t)}
    >
      {({ isSubmitting, resetForm, values }) => {
        return (
          <Form>
            <LinkPrevious
              path="/user/donations/management"
              label={t(
                'online-giving.my-donations.back-link.label',
                'My Donations'
              )}
            />
            <Title>
              {t(
                'online-giving.my-donations.title',
                'Government Identification Number'
              )}
            </Title>
            <p className="text-big mb-2">
              {t(
                'online-giving.my-donations.italy-fc-govt-notification',
                'To receive a tax receipt, donors giving in Italy must provide their fiscal code issued by the Italian Tax Authority (Agenzia delle Entrate).'
              )}
            </p>
            <h2 className="mt-18">
              {t(
                'online-giving.my-donations.manage-italy-fc-label',
                'Manage your Italian Fiscal Code'
              )}
            </h2>
            <p>
              {t(
                'online-giving.my-donations.italy-fc-notify-user',
                `You can add your Fiscal Code. To make a correction to your Fiscal Code as held in your Rotary records, email <a href="CodiceFiscaleItalia@rotary.org">CodiceFiscaleItalia@rotary.org</a>.`
              )}
            </p>

            <p className="text-big mb-2">{fullName}</p>

            {state.isDisplay ? (
              <div className="w-min">
                <PasswordInput
                  name="fiscalCodeReadonly"
                  value={stateFiscalCode.userFiscalCodeInfo}
                  type="text"
                  passwordShowHide
                  isItalyFiscalCode
                />
              </div>
            ) : (
              <div>
                <div className="w-1/2">
                  <PasswordInput
                    name="fiscalCode"
                    label={t(
                      'online-giving.my-donations.italy-fc.label',
                      'Italian Fiscal Code (alphanumeric code of 16 characters)'
                    )}
                    value={values.fiscalCode || ''}
                  />
                  <PasswordInput
                    name="confirmFiscalCode"
                    label={t(
                      'online-giving.my-donations.italy-fc.confirm-label',
                      'Confirm your Italian Fiscal Code (alphanumeric code of 16 characters)'
                    )}
                    passwordShowHide={!isEdge && true}
                    value={values.confirmFiscalCode || ''}
                  />
                </div>
                <p className="pt-7">
                  {t(
                    'online-giving.my-donations.italy-fc.privacy-policy-description',
                    'All data are kept by Rotary and the Fondazione Rotary Italy secure and inaccessible to third parties in accordance to European privacy regulation "GDPR 679/16".'
                  )}
                </p>
                <div className="tablet:flex mt-16">
                  <Button
                    className="mb-5 tablet:mr-5 tablet:mb-0"
                    disabled={isSubmitting}
                  >
                    {t(
                      'online-giving.my-donations.form.save-button-label',
                      'Save'
                    )}
                  </Button>
                  <Button
                    type="button"
                    secondary
                    disabled={isSubmitting}
                    clickHandler={resetForm}
                  >
                    {t(
                      'online-giving.my-donations.form.cancel-button-label',
                      'Cancel'
                    )}
                  </Button>
                </div>
              </div>
            )}
            <div className="pt-48">
              <Icon
                name="squares/secure"
                color="white"
                size="20"
                className="m-auto block"
              />
              <p className="text-center m-5">
                {t(
                  'online-giving.my-donations.data-safety.italy-fc',
                  'Your data is safe.'
                )}
              </p>
              <hr className="border-black" />
              <p className="mt-5">
                {t(
                  'online-giving.my-donations.privacy-policy.italy-fc',
                  'Your privacy is important to Rotary. The personal data you share with Rotary will only be used for official Rotary business. This means that the personal data you provide will primarily be used for financial processing, supporting The Rotary Foundation, communicating key organizational messages and responding to your inquiries. Personal data collected on this form is subject to <a href="https://my.rotary.org/en/privacy-policy" target="_blank">Rotary’s Privacy Policy</a>.'
                )}
              </p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ItalyFiscalCodeForm;
